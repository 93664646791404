import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://localhost:8081', getBrandConfig('vc-za-en'));

brandConfigMap.set('https://mzrhythm.com', getBrandConfig('mtn-za-en'));
brandConfigMap.set('https://staging.mzrhythm.com', getBrandConfig('mtn-za-en'));

brandConfigMap.set('https://staging.beatthejams.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://www.beatthejams.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://play.beatthejams.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://web.beatthejams.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://beatthejams.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.0.112:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.0.16:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.0.114:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://play.beatthejams.co.za', getBrandConfig('vc-encrypt-za-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        freePlayServiceId: brandConfigObject.freePlayServiceId,
        newIntegration: brandConfigObject.newIntegration,
        baseRoute: brandConfigObject.baseRoute,
    }
}
